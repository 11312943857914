import axios from "axios";
import router from "@/router";

export default {
	state: {
		email: {
			type: "email",
			name: "email",
			value: null,
			error: null,
			status: "default"
		},
		password: {
			type: "password",
			name: "password",
			value: null,
			error: null,
			status: "default"
		},
		user: {
			id: +localStorage.getItem("authorization__user-id") || null,
			token: localStorage.getItem("authorization__user-token") || null,
			role: localStorage.getItem("authorization__user-role") || null,
			first_name: localStorage.getItem("authorization__user-firstname") || null,
			middle_name: localStorage.getItem("authorization__user-middlename") || null,
			last_name: localStorage.getItem("authorization__user-lastname") || null
		},
		validation: false,
		status: null,
		errors: null
	},
	getters: {
		getAuthorizationEmail: state => state.email,
		getAuthorizationPassword: state => state.password,
		getAuthorizationValidation: state => state.validation,
		getAuthorizationUser: state => state.user
	},
	mutations: {
		handleAuthorizationChanges(state, payload) {
			if (payload.event === "change" || payload.event === "paste" || payload.event === "input") {
				let module = {
					state: state,
					payload: payload
				};

				this.commit("handleValidation", module);
			}
		},
		setAuthorizationSuccess(state, response) {
			state.user.id = response.data.user.id;
			state.user.token = response.data.jwt;
			state.status = "success";
			state.errors = null;

			localStorage.setItem("authorization__user-id", response.data.user.id);
			localStorage.setItem("authorization__user-token", response.data.jwt);
		},
		setAuthorizationInfo(state, response) {
			state.user.role = response.data.role.type;
			state.user.first_name = response.data.first_name;
			state.user.middle_name = response.data.middle_name;
			state.user.last_name = response.data.last_name;
			state.user.acronym = response.data.acronym !== null ? response.data.acronym : "AW";
			state.user.phone = response.data.phone;

			localStorage.setItem("authorization__user-role", response.data.role.type);
			localStorage.setItem("authorization__user-firstname", response.data.first_name);
			localStorage.setItem("authorization__user-middlename", response.data.middle_name);
			localStorage.setItem("authorization__user-lastname", response.data.last_name);
			localStorage.setItem(
				"authorization__user-acronym",
				response.data.acronym !== null ? response.data.acronym : "AW"
			);
			localStorage.setItem("authorization__user-phone", response.data.phone);

			router.push("/profile");
		},
		setAuthorizationFailure(state, response) {
			state.status = "error";
			state.errors = String(response);

			localStorage.removeItem("authorization__user-id");
			localStorage.removeItem("authorization__user-token");
			localStorage.removeItem("authorization__user-role");
			localStorage.removeItem("authorization__user-firstname");
			localStorage.removeItem("authorization__user-middlename");
			localStorage.removeItem("authorization__user-lastname");
			localStorage.removeItem("authorization__user-acronym");
			localStorage.removeItem("authorization__user-phone");
		},
		makeLogoutAuthorization(state) {
			state.user.id = null;
			state.user.token = null;
			state.user.role = null;
			state.user.first_name = null;
			state.user.middle_name = null;
			state.user.last_name = null;
			state.user.acronym = null;
			state.status = null;
			state.errors = null;

			state.email.value = null;
			state.email.error = null;
			state.email.status = "default";

			state.password.value = null;
			state.password.error = null;
			state.password.status = "default";

			localStorage.clear();

			this.commit("clearCommonState");
			this.commit("clearProfileState");
			this.commit("clearObjectsState");
			this.commit("clearSystemState");
		}
	},
	actions: {
		loginAuthorization(context, user) {
			return new Promise((resolve, reject) => {
				context.commit("showPreloader");

				axios
					.post("https://test-api.airwet.app/v1/auth/local", user)
					.then(response => {
						context.commit("setAuthorizationSuccess", response.data);
						context.commit("setHeaders", response.data.data.jwt);

						axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.data.jwt}`;
						axios
							.get("https://test-api.airwet.app/v1/users/me", {
								headers: {
									Authorization: `Bearer ${response.data.data.jwt}`
								}
							})
							.then(response => {
								if (response.data.role.type === "dispatcher")
									window.location.href = "https://host.airwet.app/";

								context.commit("hidePreloader");
								context.commit("setAuthorizationInfo", response);
							});
						resolve(response);
					})
					.catch(error => {
						context.commit("handleError", error);
						context.commit("setAuthorizationFailure", error);

						reject(error);
					});
			});
		},
		logoutAuthorization(context) {
			return new Promise(resolve => {
				context.commit("makeLogoutAuthorization");
				context.commit("removeHeaders");
				context.commit("hidePreloader");
				delete axios.defaults.headers.common["Authorization"];
				resolve();
			});
		}
	}
};
