import {
  createRouter,
  createWebHistory
} from 'vue-router'

import Confirm from "../pages/Confirm.vue";
import Invite from "../pages/Invite.vue";
import Login from "../pages/Login.vue";
import Nothing from "../pages/Nothing.vue";
import Objects from "../pages/Objects.vue";
import Profile from "../pages/Profile.vue";
import Recover from "../pages/Recover.vue";
import Register from "../pages/Register.vue";
import Reset from "../pages/Reset.vue";
import Set from "../pages/Set.vue";
import System from "../pages/System.vue";
import Systems from "../pages/Systems.vue";


const routes = [{
    path: '/',
    redirect: '/login'
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: Confirm,
  },
  {
    path: '/invite',
    name: 'invite',
    component: Invite
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/objects/:id',
    name: 'objects',
    component: Objects
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/recover',
    name: 'recover',
    component: Recover
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/reset',
    name: 'reset',
    component: Reset
  },
  {
    path: '/set',
    name: 'set',
    component: Set,
  },
  {
    path: '/system/:id',
    name: 'system',
    component: System,
  },
  {
    path: '/systems',
    name: 'systems',
    component: Systems
  },
  {
    path: '/:pathMatch(.*)',
    name: 'nothing',
    component: Nothing
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  const publicPages = [
    '/confirm',
    '/home',
    '/invite',
    '/login',
    '/nothing',
    '/recover',
    '/register',
    '/reset',
    '/set',
  ];

  const userPages = [
    '/objects',
  ];

  const engineerPages = [
    '/systems',
    `/system/${to.params.id}`,
  ];

  const authRequired = !publicPages.includes(to.path);
  const userRequired = userPages.includes(to.path);
  const engineerRequired = engineerPages.includes(to.path);
  const loggedIn = localStorage.getItem('authorization__user-role');

  if (authRequired && !loggedIn) next('/login');
  else if (userRequired && loggedIn !== 'user') next('/nothing');
  else if (engineerRequired && loggedIn !== 'engineer') next('/nothing');

  else next();
});

export default router